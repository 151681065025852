// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment__wrsFmdvPLa{border-top:1px solid #e3e4e6;border-bottom:1px solid #e3e4e6;padding:1em 0px;color:#5a5a5a}.thankYou__NDFvBc787g{color:#132244;font-size:1.4em;font-weight:600;text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/thankYou/thankYou.scss","webpack://./src/mixins.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAGA,qBACE,4BAAA,CACA,+BAAA,CACA,eAAA,CACA,aAAA,CAGF,sBC2BE,aC/BY,CDgCZ,eAAA,CACA,eAAA,CD3BA,iBAAA","sourceRoot":""}]);
// Exports
export var comment = `comment__wrsFmdvPLa`;
export var thankYou = `thankYou__NDFvBc787g`;
export default ___CSS_LOADER_EXPORT___;
