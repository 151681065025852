import { h, Fragment, Component, createRef, RefObject } from 'preact';
import classnames from 'classnames';

import * as style from './commentForm.scss';
import { ratingRequest } from '../../utils/request';
import { TextDirContext } from '../../contexts/textDir';
import { DisabledContext } from '../../contexts/disabled';
import { getTextDir } from '../../utils/getTextDir';
import { log } from '../../utils/log';
import { EffectiveCustomization, ScoreT } from '../../types/response';
import { DisplayContext } from '../../contexts/display';

type PropsT = Pick<
  EffectiveCustomization,
  'commentQuestion' | 'placeholderText' | 'buttonText'
> & {
  ratingId?: string;
  titleMessage: string;
  score?: ScoreT;
  fetchedScore?: ScoreT;
  patchScore: (score: ScoreT) => Promise<void>;
  onSubmit?: (comment: string | null) => void;
  onError?: (e: Error) => void;
};

type StateT = {
  dir: 'ltr' | 'rtl' | '';
};

export class CommentForm extends Component<PropsT, StateT> {
  static contextType = TextDirContext;

  private readonly textarea: RefObject<HTMLTextAreaElement>;

  constructor(props: PropsT) {
    super(props);

    this.state = {
      dir: 'ltr',
    };

    this.textarea = createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(): void {
    this.setTextDir();
  }

  componentDidUpdate(prevProps: PropsT): void {
    const { placeholderText } = this.props;

    if (placeholderText !== prevProps.placeholderText) {
      this.setTextDir();
    }
  }

  // There's a bug in chrome that causes placeholder text to ignore dir="auto"
  // which is marked as 'WontFix' - https://bugs.chromium.org/p/chromium/issues/detail?id=982674
  // The actual text direction can be determined by looking at the computed value
  // (which is why there's a check for textDirProp?.dir === 'auto')
  setTextDir(): void {
    const { textDirProp } = this.context;
    const { placeholderText } = this.props;

    if (placeholderText && textDirProp?.dir === 'auto') {
      this.setState({
        dir: getTextDir(placeholderText),
      });
    }
  }

  async handleSubmit(
    setDisabledFeedbackButton: (disable: boolean) => void,
    setDisabledIcons: (disable: boolean) => void,
    setDisplayUnsubscribe: (display: boolean) => void,
    setDisplayTitleMessage: (display: boolean) => void,
  ): Promise<void> {
    log.events.push('click - submit');
    setDisabledFeedbackButton(true);
    setDisabledIcons(true);
    setDisplayUnsubscribe(false);
    setDisplayTitleMessage(false);

    const { onSubmit, onError, ratingId, score, fetchedScore, patchScore } =
      this.props;

    // Can't send a null or empty comment, so converting it to a single space
    const val = this.textarea?.current?.value?.trim() || ' ';

    if (ratingId) {
      try {
        // Only patch the score if it has changed
        if (score && score !== fetchedScore) {
          await patchScore(score);
        }

        await ratingRequest(ratingId, 'PATCH', {
          comment: val,
        });

        onSubmit?.(val);
      } catch (e) {
        onError?.(e as Error);
        setDisabledFeedbackButton(false);
        setDisabledIcons(false);
        setDisplayUnsubscribe(true);
        setDisplayTitleMessage(true);
      }
    }
  }

  render(): h.JSX.Element {
    const { commentQuestion, placeholderText, buttonText } = this.props;
    const { dir } = this.state;
    const { textDirProp } = this.context;

    return (
      <Fragment>
        {commentQuestion && (
          <p className={style.question} {...textDirProp}>
            {commentQuestion}
          </p>
        )}
        <textarea
          className={classnames(style.commentFormTextArea, style[dir])}
          form="commentform"
          name="comment"
          placeholder={placeholderText}
          ref={this.textarea}
          aria-label={placeholderText}
          {...textDirProp}
        />
        <DisplayContext.Consumer>
          {(displayContext) => (
            <DisabledContext.Consumer>
              {(disabledContext) => (
                <Fragment>
                  <button
                    className={classnames(style.submitButton, {
                      [style.disabled]: disabledContext.disabledFeedbackButton,
                    })}
                    type="submit"
                    onClick={() =>
                      this.handleSubmit(
                        disabledContext.setDisabledFeedbackButton,
                        disabledContext.setDisabledIcons,
                        displayContext.setDisplayUnsubscribe,
                        displayContext.setDisplayTitleMessage,
                      )
                    }
                    disabled={disabledContext.disabledFeedbackButton}
                    {...textDirProp}
                  >
                    {buttonText}
                  </button>
                </Fragment>
              )}
            </DisabledContext.Consumer>
          )}
        </DisplayContext.Consumer>
      </Fragment>
    );
  }
}
