/**
 * Get the text direction of a string
 *
 * This will create a hidden element with the text, set the attribute `dir="auto"`
 * and return the element's computed direction.
 *
 * Note: since this uses `dir="auto"` this won't work with IE
 */
export const getTextDir = (text: string): 'ltr' | 'rtl' | '' => {
  const elem = document.createElement('p');
  elem.hidden = true;
  elem.dir = 'auto';
  elem.innerText = text;
  document.body.appendChild(elem);
  const dir = window.getComputedStyle(elem).direction;
  document.body.removeChild(elem);

  return dir as 'ltr' | 'rtl' | '';
};
