import { FunctionalComponent, h, Fragment } from 'preact';
import classnames from 'classnames';

import { RatingComponentProps } from './rating';
import { Scores, ScoreT } from '../../types/response';

import * as style from './rating.scss';

import AngerEmoji from './icons/emojis/anger.png';
import SadEmoji from './icons/emojis/sad.png';
import MmmhEmoji from './icons/emojis/mmmh.png';
import HappyEmoji from './icons/emojis/happy.png';
import AwesomeEmoji from './icons/emojis/awesome.png';

const ratings: Record<ScoreT, string> = {
  1: AngerEmoji,
  2: SadEmoji,
  3: MmmhEmoji,
  4: HappyEmoji,
  5: AwesomeEmoji,
};

export const Emojis: FunctionalComponent<RatingComponentProps> = ({
  selectedScore,
  onChange,
  disabledIcons,
}: RatingComponentProps) => (
  <Fragment>
    {Scores.map((score) => {
      const isSelected = score === selectedScore;
      const isDisabled = disabledIcons || isSelected;

      return (
        <button
          key={score}
          onClick={isDisabled ? undefined : () => onChange(score)}
          className={classnames(style.ratingButton, {
            [style.selected]: isSelected,
            [style.enabled]: disabledIcons ? false : !isSelected,
          })}
          disabled={isDisabled}
        >
          <img src={ratings[score]} alt={score} className={style.imgIcon} />
        </button>
      );
    })}
  </Fragment>
);
