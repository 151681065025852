// Check if running as localhost so that we don't send errors to Sentry
declare global {
  interface Window {
    isLocal: boolean;
  }
}

export const checkIfLocalHost = (): void => {
  window.isLocal = ['localhost', '127.0.0.1', ''].includes(
    window.location.hostname,
  );
};
