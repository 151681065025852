import { FunctionalComponent, h, Fragment } from 'preact';
import classnames from 'classnames';

import { RatingComponentProps } from './rating';

import Number1 from './icons/numbers/number-1.svg';
import Number2 from './icons/numbers/number-2.svg';
import Number3 from './icons/numbers/number-3.svg';
import Number4 from './icons/numbers/number-4.svg';
import Number5 from './icons/numbers/number-5.svg';
import Number1Selected from './icons/numbers/number-1-selected.svg';
import Number2Selected from './icons/numbers/number-2-selected.svg';
import Number3Selected from './icons/numbers/number-3-selected.svg';
import Number4Selected from './icons/numbers/number-4-selected.svg';
import Number5Selected from './icons/numbers/number-5-selected.svg';

import { Scores } from '../../types/response';

import * as style from './rating.scss';

const ratings = {
  1: { unselected: Number1, selected: Number1Selected },
  2: { unselected: Number2, selected: Number2Selected },
  3: { unselected: Number3, selected: Number3Selected },
  4: { unselected: Number4, selected: Number4Selected },
  5: { unselected: Number5, selected: Number5Selected },
};

export const Numbers: FunctionalComponent<RatingComponentProps> = ({
  selectedScore,
  onChange,
  disabledIcons,
}: RatingComponentProps) => {
  return (
    <Fragment>
      {Scores.map((score) => {
        const isSelected = score === selectedScore;
        const { selected, unselected } = ratings[score];
        const isDisabled = disabledIcons || isSelected;

        return (
          <button
            key={score}
            onClick={isDisabled ? undefined : () => onChange(score)}
            className={classnames(style.ratingButton, {
              [style.selected]: isSelected,
              [style.enabled]: !disabledIcons && !isSelected,
            })}
            disabled={isDisabled}
          >
            <img
              src={isSelected ? selected : unselected}
              alt={score}
              className={style.svgIcon}
            />
          </button>
        );
      })}
    </Fragment>
  );
};
