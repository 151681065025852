import { LanguageCode } from '../components/unsubscribe/texts';

export const ScoreIconStyles = ['emojis', 'stars', 'numbers'] as const;
export type ScoreIconStyleT = (typeof ScoreIconStyles)[number];

export const Scores = ['1', '2', '3', '4', '5'] as const;
export type ScoreT = (typeof Scores)[number];

export const isValidScore = (score: unknown): score is ScoreT =>
  Scores.includes(score as ScoreT);

export const isValidScoreIconStyle = (
  scoreIconStyle: string | null,
): scoreIconStyle is ScoreIconStyleT =>
  ScoreIconStyles.includes(scoreIconStyle as ScoreIconStyleT);

export type EffectiveCustomizationResponse = {
  thank_you_message: string | null;
  comment_question: string | null;
  placeholder_text: string | null;
  button_text: string | null;
  rating_question: string | null;
  look_and_feel: ScoreIconStyleT | null;
};

export type Data = {
  data: {
    effective_customization: EffectiveCustomizationResponse;
    score: 1 | 2 | 3 | 4 | 5 | null;
    comment: string | null;
    language?: LanguageCode;
  };
};

// note: the response has `rating_question`, but this is only used in the
// csat email, not here in the landing page
export type EffectiveCustomization = {
  ratingQuestion: EffectiveCustomizationResponse['rating_question'];
  thankYouMessage: EffectiveCustomizationResponse['thank_you_message'];
  commentQuestion: EffectiveCustomizationResponse['comment_question'];
  placeholderText: EffectiveCustomizationResponse['placeholder_text'];
  buttonText: EffectiveCustomizationResponse['button_text'];
  scoreIconStyle: EffectiveCustomizationResponse['look_and_feel'];
};
