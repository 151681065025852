import { createContext } from 'preact';

export type DisplayContext = {
  displayTextField: boolean;
  displayUnsubscribe: boolean;
  displayTitleMessage: boolean;
  setDisplay: (
    displayTextField: boolean,
    displayUnsubscribe: boolean,
    displayTitleMessage: boolean,
  ) => void;
  setDisplayTextField: (disabledTextField: boolean) => void;
  setDisplayUnsubscribe: (displayUnsubscribe: boolean) => void;
  setDisplayTitleMessage: (displayUnsubscribe: boolean) => void;
};

/* eslint-disable */
export const defaultDisplayContext: DisplayContext = {
  displayTextField: false,
  displayUnsubscribe: true,
  displayTitleMessage: true,
  setDisplay: (
    displayTextField: boolean,
    displayUnsubscribe: boolean,
    displayTitleMessage: boolean,
  ) => {},
  setDisplayTextField: (disabledTextField: boolean) => {},
  setDisplayUnsubscribe: (displayUnsubscribe: boolean) => {},
  setDisplayTitleMessage: (displayTitleMessage: boolean) => {},
};
/* eslint-enable */

export const DisplayContext = createContext(defaultDisplayContext);
