interface Log {
  events: Array<string>;
  score: number | null;
  comment: string | null;
}

export const log: Log = {
  events: [],
  score: null,
  comment: null,
};
