// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root__QdKZgd7J7A{width:auto;height:auto;width:100%;height:100%;padding:0px 5%;box-sizing:border-box;display:flex;flex-direction:column;justify-content:center;align-items:center}@media only screen and (min-width: 600px)and (min-height: 600px){.root__QdKZgd7J7A{max-width:500px;max-height:800px;height:auto;width:auto;border-radius:6px;box-shadow:0px 2px 8px rgba(0,0,0,.1);padding:24px}}.iconContainer__p20at6YMw6{display:flex;justify-content:center}.icon__UwE4gA0mh8{width:48px;height:48px}.error__UkxC0KiJ0Z{font-family:Open Sans,sans-serif;font-style:normal;font-weight:400;font-size:18px;color:#393939;margin:25px 0;text-align:center}.instructions__xsuoKmb62H{box-sizing:border-box;font-size:14px;color:#5a5a5a;text-align:center;padding:16px;border-top:1px solid #e3e4e6;border-bottom:1px solid #e3e4e6}`, "",{"version":3,"sources":["webpack://./src/components/error/error.scss","webpack://./src/mixins.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAGA,kBACE,UAAA,CACA,WAAA,CCQA,UAAA,CACA,WAAA,CACA,cAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAnBA,iEDEF,kBCqBI,eAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA,CAGA,iBCnBiB,CDoBjB,qCAAA,CACA,YAAA,CAAA,CDtBJ,2BACE,YAAA,CACA,sBAAA,CAGF,kBACE,UAAA,CACA,WAAA,CAGF,mBACE,gCAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,aEjBK,CFmBL,aAAA,CAEA,iBAAA,CAGF,0BACE,qBAAA,CAEA,cAAA,CACA,aE7BW,CF+BX,iBAAA,CAEA,YAAA,CACA,4BAAA,CACA,+BAAA","sourceRoot":""}]);
// Exports
export var root = `root__QdKZgd7J7A`;
export var iconContainer = `iconContainer__p20at6YMw6`;
export var icon = `icon__UwE4gA0mh8`;
export var error = `error__UkxC0KiJ0Z`;
export var instructions = `instructions__xsuoKmb62H`;
export default ___CSS_LOADER_EXPORT___;
