// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root__Dv4H8nyLMZ{width:100%;height:100%;padding:0px 5%;box-sizing:border-box;display:flex;flex-direction:column;justify-content:center;align-items:center}@media only screen and (min-width: 600px)and (min-height: 600px){.root__Dv4H8nyLMZ{max-width:500px;max-height:800px;height:auto;width:auto;border-radius:6px;box-shadow:0px 2px 8px rgba(0,0,0,.1);padding:24px}}.root__Dv4H8nyLMZ>*{margin:.5em 0}.root__Dv4H8nyLMZ>*:first-child{margin-top:1em}.root__Dv4H8nyLMZ>*:last-child{margin-bottom:1em}@media only screen and (max-height: 400px){.root__Dv4H8nyLMZ>*{margin:.25em 0}.root__Dv4H8nyLMZ>*:first-child{margin-top:.5em}.root__Dv4H8nyLMZ>*:last-child{margin-bottom:.5em}}.root__Dv4H8nyLMZ .thankYou__BATepX83Yz{color:#132244;font-size:1.4em;font-weight:600;text-align:center}.unsubscribeLink__LUr5IbaNx_{font-size:12px;color:#617698}`, "",{"version":3,"sources":["webpack://./src/components/feedback/feedback.scss","webpack://./src/mixins.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAGA,kBCUE,UAAA,CACA,WAAA,CACA,cAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAnBA,iEDEF,kBCqBI,eAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA,CAGA,iBCnBiB,CDoBjB,qCAAA,CACA,YAAA,CAAA,CD1BF,oBACE,aAAA,CACA,gCACE,cAAA,CAEF,+BACE,iBAAA,CCLJ,2CDUE,oBACE,cAAA,CACA,gCACE,eAAA,CAEF,+BACE,kBAAA,CAAA,CAKN,wCCSA,aC/BY,CDgCZ,eAAA,CACA,eAAA,CDTE,iBAAA,CAIJ,6BACE,cAAA,CACA,aE/BY","sourceRoot":""}]);
// Exports
export var root = `root__Dv4H8nyLMZ`;
export var thankYou = `thankYou__BATepX83Yz`;
export var unsubscribeLink = `unsubscribeLink__LUr5IbaNx_`;
export default ___CSS_LOADER_EXPORT___;
