// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question__2UOVJ8A65n{color:#617698;text-align:center}.commentFormTextArea__sy_f4736Kg{border:1px solid #d7dfea;border-radius:6px;width:100%;height:100%;font-size:1em;padding:.75em 1em;box-sizing:border-box;min-width:250px;max-width:100%;overflow:auto;flex-basis:150px;flex-grow:1}.commentFormTextArea__sy_f4736Kg::placeholder{color:#b5c1d4}.commentFormTextArea__sy_f4736Kg.ltr__dIqwHK_K_F::placeholder{direction:ltr}.commentFormTextArea__sy_f4736Kg.rtl__fm8micMXLN::placeholder{direction:rtl}@media only screen and (min-width: 600px)and (min-height: 600px){.commentFormTextArea__sy_f4736Kg{max-height:40%;min-height:150px}}.submitButton__f7kwSuEkL0{-webkit-appearance:none;appearance:none;background:#5644d8;border:none;border-radius:6px;font-size:1em;font-weight:600;font-family:inherit;white-space:normal;color:#fff;padding:1em 5em;text-align:center;cursor:pointer}@media only screen and (min-width: 600px)and (min-height: 600px){.submitButton__f7kwSuEkL0{padding:.5em 2.5em}}.thankYou__N85BiW68P7{color:#132244;font-size:1.4em;font-weight:600;text-align:center}.disabled__hrTnASJ4CC{cursor:not-allowed;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/commentForm/commentForm.scss","webpack://./src/variables.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA,sBACE,aCCY,CAAA,iBAAA,CDGd,iCACE,wBAAA,CACA,iBCCmB,CAAA,UAAA,CDCnB,WAAA,CACA,aAAA,CACA,iBAAA,CACA,qBAAA,CACA,eAAA,CACA,cAAA,CACA,aAAA,CACA,gBAAA,CACA,WAAA,CACA,8CACE,aClBU,CDoBZ,8DACE,aAAA,CAEF,8DACE,aAAA,CE3BF,iEFOF,iCAwBI,cAAA,CACA,gBAAA,CAAA,CAIJ,0BEME,uBAAA,CAAA,eAAA,CACA,kBD1CW,CC2CX,WAAA,CACA,iBDnCmB,CCoCnB,aAAA,CACA,eAAA,CACA,mBAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CArDA,iEFoCF,0BEoBI,kBAAA,CAAA,CFhBJ,sBEJE,aD/BY,CCgCZ,eAAA,CACA,eAAA,CFIA,iBAAA,CAGF,sBACE,kBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
export var question = `question__2UOVJ8A65n`;
export var commentFormTextArea = `commentFormTextArea__sy_f4736Kg`;
export var ltr = `ltr__dIqwHK_K_F`;
export var rtl = `rtl__fm8micMXLN`;
export var submitButton = `submitButton__f7kwSuEkL0`;
export var thankYou = `thankYou__N85BiW68P7`;
export var disabled = `disabled__hrTnASJ4CC`;
export default ___CSS_LOADER_EXPORT___;
