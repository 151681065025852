export type LanguageCode =
  | 'en'
  | 'ar'
  | 'zh'
  | 'da'
  | 'et'
  | 'fi'
  | 'fr'
  | 'de'
  | 'is'
  | 'it'
  | 'ja'
  | 'ko'
  | 'lv'
  | 'lt'
  | 'no'
  | 'nl'
  | 'pl'
  | 'pt'
  | 'ru'
  | 'es'
  | 'sv'
  | 'th'
  | 'tr';

export const unsubscribeTexts: Record<
  LanguageCode,
  Record<'question' | 'button' | 'feedback' | 'error', string>
> = {
  en: {
    question: 'Are you sure you would like to unsubscribe?',
    button: 'Unsubscribe',
    feedback: 'Unsubscribed successfully!',
    error: 'Unable to unsubscribe',
  },
  ar: {
    question: 'هل أنت متأكد أنك تريد إلغاء الاشتراك؟',
    button: 'إلغاء الاشتراك',
    feedback: ' تم إلغاء الاشتراك بنجاح',
    error: 'تعذر إلغاء الاشتراك',
  },
  zh: {
    question: '您确定要取消订阅吗?',
    button: '取消订阅',
    feedback: '成功取消订阅',
    error: '无法取消订阅',
  },
  da: {
    question: 'Er du sikker på, at du gerne vil afmelde?',
    button: 'Afmeld',
    feedback: 'Afmeldingen lykkedes',
    error: 'Kunne ikke afmelde',
  },
  nl: {
    question: 'Weet je zeker dat je je wilt uitschrijven?',
    button: 'Uitschrijven',
    feedback: 'Succesvol uitgeschreven',
    error: 'Kan niet uitschrijven',
  },
  et: {
    question: 'Kas olete kindel, et soovite tellimuse tühistada?',
    button: 'Loobuge tellimusest',
    feedback: 'Tellimuse tühistamine õnnestus!',
    error: 'Tellimust ei saa tühistada',
  },
  fi: {
    question: 'Tilauksen peruuttaminen ei onnistu',
    button: 'Lopeta tilaus',
    feedback: 'Tilauksen peruuttaminen onnistui!',
    error: 'Tilauksen peruuttaminen ei onnistu',
  },
  fr: {
    question: 'Êtes-vous sûr(e) de vouloir vous désabonner?',
    button: 'Se désabonner',
    feedback: 'Désabonnement réussi!',
    error: 'Impossible de se désabonner',
  },
  de: {
    question: 'Möchten Sie sich wirklich abmelden?',
    button: 'Abbestellen',
    feedback: 'Erfolgreich abgemeldet!',
    error: 'Abmeldung nicht möglich',
  },
  is: {
    question: 'Ertu viss um að þú viljir segja upp áskrift?',
    button: 'Hætta áskrift',
    feedback: 'Áskrift tókst!',
    error: 'Ekki tókst að segja upp áskrift',
  },
  it: {
    question: 'Sei sicuro di voler cancellare la sottoscrizione?',
    button: 'Cancellami',
    feedback: 'Sottoscrizione cancellata con successo!',
    error: 'Impossibile cancellare la sottoscrizione',
  },
  ja: {
    question: '退会してもよろしいですか？',
    button: '登録を解除する',
    feedback: '退会に成功しました!',
    error: '登録を解除できません',
  },
  ko: {
    question: '구독을 취소하시겠습니까?',
    button: '구독 취소',
    feedback: '구독 취소에 성공했습니다!',
    error: '구독 취소할 수 없음',
  },
  lv: {
    question: 'Nevar anulēt abonementu',
    button: 'Anulēt abonementu',
    feedback: 'Abonēšana sekmīgi atcelta!',
    error: 'Nevar anulēt abonementu',
  },
  lt: {
    question: 'Ar tikrai norite atsisakyti prenumeratos?',
    button: 'Atsisakyti prenumeratos',
    feedback: 'Prenumerata sėkmingai atšaukta!',
    error: 'Nepavyko atšaukti prenumeratos',
  },
  no: {
    question: 'Er du sikker på at du vil melde deg av?',
    button: 'Meld av',
    feedback: 'Avbestilling vellykket',
    error: 'Kan ikke melde av',
  },
  pl: {
    question: 'Czy na pewno chcesz zrezygnować z subskrypcji?',
    button: 'Anuluj subskrypcję',
    feedback: 'Anulowano subskrypcję!',
    error: 'Nie można zrezygnować z subskrypcji',
  },
  pt: {
    question: 'Tem a certeza de que deseja cancelar a subscrição?',
    button: 'Cancelar subscrição',
    feedback: 'Subscrição cancelada!',
    error: 'Não foi possível cancelar a subscrição',
  },
  ru: {
    question: 'Вы уверены, что хотите отказаться от подписки?',
    button: 'Отписаться от рассылки',
    feedback: 'Подписка успешно отменена!',
    error: 'Невозможно отказаться от подписки',
  },
  es: {
    question: '¿Estás seguro que quiere darse de baja?',
    button: 'Darse de baja',
    feedback: '¡Se canceló la suscripción con éxito!',
    error: 'No se puede cancelar la suscripción.',
  },
  sv: {
    question: 'Är du säker på att du vill avregistrera dig?',
    button: 'Avregistrera',
    feedback: 'Avregistrering genomförd!',
    error: 'Avregistreringen misslyckades',
  },
  th: {
    question: 'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการสมัคร',
    button: 'ยกเลิกการสมัคร',
    feedback: 'ยกเลิกการสมัครสำเร็จแล้ว!',
    error: 'ยกเลิกการสมัครไม่ได้',
  },
  tr: {
    question: 'Aboneliğinden çıkmak istediğinizden emin misiniz?',
    button: 'Abonelikten çık',
    feedback: 'Abonelikten başarıyla çıkıldı!',
    error: 'Abonelikten çıkılamadı',
  },
};
