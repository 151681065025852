import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import * as Sentry from '@sentry/browser';
import { log } from '../../utils/log';

export const Honeypot: FunctionalComponent<{ onClick?: VoidFunction }> = ({
  onClick,
}) => {
  const handleClick = () => {
    onClick?.();
    log.events.push(`click - honeypot`);
    Sentry.captureMessage('Honeypot clicked', { level: 'log' });
    route('/');
  };

  return (
    <button
      onClick={handleClick}
      style={{
        position: 'absolute',
        left: '-1px',
        top: '-1px',
        width: '1px',
        height: '1px',
        backgroundColor: 'transparent',
        border: 'none',
        margin: 0,
        padding: 0,
      }}
    />
  );
};
