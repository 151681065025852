import { FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';

import { UnsubscribeParams, unsubscribeRequest } from '../../utils/request';
import { LanguageCode } from './texts';
import { Unsubscribe } from './unsubscribe';
import * as style from './unsubscribe.scss';

type PropsT = UnsubscribeParams & { lang?: LanguageCode };

export enum AsyncStatus {
  Initial = 'initial',
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Failed = 'failed',
}

export const UnsubscribePage: FunctionalComponent<PropsT> = ({
  ratingId,
  lang,
}: PropsT) => {
  const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>(
    AsyncStatus.Initial,
  );

  const onUnsubscribe = async () => {
    setAsyncStatus(AsyncStatus.Pending);
    try {
      await unsubscribeRequest({
        ratingId,
      });
      setAsyncStatus(AsyncStatus.Fulfilled);
    } catch (error) {
      setAsyncStatus(AsyncStatus.Failed);
    } finally {
    }
  };

  return (
    <div id="app" className={style.root}>
      <Unsubscribe
        asyncStatus={asyncStatus}
        onUnsubscribe={onUnsubscribe}
        lang={lang}
      />
    </div>
  );
};
