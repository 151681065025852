import { Router, Route } from 'preact-router';
import { FunctionalComponent, h } from 'preact';
import { App } from '../app';
import { UnsubscribePage } from '../unsubscribe';

/**
 * URL should used to be `csat.reviews/{ratingId}?score={score}`
 * and deployed as static page in a public s3 bucket.
 * In order to move to a private bucket (for SOC2 compliance) and accessed through cloudfront,
 * we found the limitation of not being able to use path parameters,
 * so from then, ratingId will be used as query parameter too:
 * `csat.reviews/?ratingId={ratingId}&score={score}`
 * Preact Router parses the querystring automatically
 * if the querystring is like `?score=5&score=4` is uses the last one (4)
 * */
export const Routes: FunctionalComponent = () => (
  <Router>
    <Route path="/:ratingId" component={App} />
    <Route
      path="/unsubscribe/:orgId/:userId/:channel/:contactPoint?lang"
      component={UnsubscribePage}
    />
    <Route default component={App} />
  </Router>
);
