import { log } from './log';

let dixaHostName = 'api.dixa.io';
const currentHostName = window.location.hostname;

if (currentHostName.includes('stag') || currentHostName.includes('localhost')) {
  dixaHostName = 'api.euw1.stag.dixa.io';
}

const basePath = `https://${dixaHostName}/v2/ratings`;

const wait = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });

const logRequest = (
  method: 'GET' | 'PATCH',
  body?: Record<string, unknown>,
) => {
  let patchType = '';
  if (body) {
    patchType = body.score ? ' - score' : ' - comment';
  }
  log.events.push(`request - ${method}${patchType}`);
};

const request = async (
  path: string,
  method: 'GET' | 'POST' | 'PATCH',
  params?: Record<string, unknown>,
  retryTimes = 3,
): Promise<Response> => {
  const options: RequestInit = {
    method,
    mode: 'cors',
    ...(params && {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }),
  };

  let response: Response;

  try {
    // reject with a Timeout if it takes more than 10 secs to make the request
    response = await Promise.race([
      fetch(path, options),
      new Promise<never>((_, reject) =>
        setTimeout(() => reject(new Error('Timeout')), 10000),
      ),
    ]);
  } catch (e) {
    // This would be a failure to fetch which could be due to a bad network connection
    // so retry the request (should be 4 times total)
    // Wait 5 secs between each request so they don't all happen at once
    await wait(5000);

    if (retryTimes > 0) {
      return request(path, method, params, retryTimes - 1);
    }
    throw new Error(e as string | undefined);
  }

  if (!response.ok) {
    // In case of a bad response, throw an error with as much information as possible
    const responseMessage = `${response.status}: ${response.statusText}`;
    const optionsString = options ? ` - ${JSON.stringify(options)}` : '';
    const errorMessage = `${responseMessage} - ${path}${optionsString}`;
    const errorWithLog = `${errorMessage} - ${JSON.stringify(log)}`;

    throw new Error(errorWithLog);
  }

  return response;
};

export const ratingRequest = (
  ratingId: string,
  method: 'GET' | 'PATCH',
  params?: {
    score?: number;
    comment?: string;
  },
): Promise<Response> => {
  logRequest(method, params);
  const path = `${basePath}/${ratingId}`;
  return request(path, method, params);
};

export type UnsubscribeParams = {
  ratingId: string;
};

export const unsubscribeRequest = ({
  ratingId,
}: UnsubscribeParams): Promise<Response> => {
  return request(`${basePath}/unsubscribe`, 'POST', {
    rating_id: ratingId,
  });
};
