import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { h, render } from 'preact';
import * as Sentry from '@sentry/browser';
import 'whatwg-fetch';

import { checkIfLocalHost } from './setup';
import { Routes } from './components/routes';
import './global.scss';

Sentry.init({
  dsn: 'https://fe3b93a5d736484d97d70d7d657561e2@sentry.io/4158580',
});

checkIfLocalHost();

render(<Routes />, document.body);
