import { createContext } from 'preact';

// Only use the prop `dir="auto"` if the browser is not IE  (there's currently a
// bug in Preact that causes IE to white screen because of that)
type Dir = {
  dir?: 'auto';
};

type TextDirContext = {
  textDirProp?: Dir;
};

export const defaultTextDirContext: TextDirContext =
  window.navigator.userAgent.match(
    /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/,
  )
    ? {}
    : { textDirProp: { dir: 'auto' } };

export const TextDirContext = createContext(defaultTextDirContext);
