import { createContext } from 'preact';

export type DisabledContext = {
  disabledFeedbackButton: boolean;
  disabledIcons: boolean;
  setDisabled: (
    disabledFeedbackButton: boolean,
    disabledIcons: boolean,
  ) => void;
  setDisabledFeedbackButton: (disabledFeedbackButton: boolean) => void;
  setDisabledIcons: (disabledIcons: boolean) => void;
};

/* eslint-disable */
export const defaultDisabledContext: DisabledContext = {
  disabledFeedbackButton: true,
  disabledIcons: false,
  setDisabled: (disabledFeedbackButton: boolean, disabledIcons: boolean) => {},
  setDisabledFeedbackButton: (disabledFeedbackButton: boolean) => {},
  setDisabledIcons: (disabledIcons: boolean) => {},
};
/* eslint-enable */

export const DisabledContext = createContext(defaultDisabledContext);
