import { Component, h } from 'preact';
import * as Sentry from '@sentry/browser';
import { Feedback } from '../feedback';
import { Error } from '../error';
import { ratingRequest } from '../../utils/request';
import { TextDirContext, defaultTextDirContext } from '../../contexts/textDir';
import {
  DisabledContext,
  defaultDisabledContext,
} from '../../contexts/disabled';
import { DisplayContext, defaultDisplayContext } from '../../contexts/display';
import { log } from '../../utils/log';
import {
  Data,
  EffectiveCustomization,
  ScoreT,
  isValidScore,
  isValidScoreIconStyle,
} from '../../types/response';
import { UnsubscribePage } from '../unsubscribe';
import { LanguageCode } from '../unsubscribe/texts';

enum FetchStateE {
  PENDING,
  GET_SUCCESS,
  FAILED,
}

type PropsT = {
  unsubscribe?: string;
  score?: string | null;
  ratingId?: string;
};

type StateT = DisabledContext &
  DisplayContext &
  EffectiveCustomization & {
    commentText: string | null;
    fetchState: FetchStateE;
    fetchedScore: string | null;
    language?: LanguageCode;
  };

export class App extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    const { score } = props;

    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.patchScore = this.patchScore.bind(this);
    this.setDisplayTextField = this.setDisplayTextField.bind(this);
    this.setDisplayUnsubscribe = this.setDisplayUnsubscribe.bind(this);
    this.setDisplayTitleMessage = this.setDisplayTitleMessage.bind(this);
    this.setDisabledFeedbackButton = this.setDisabledFeedbackButton.bind(this);
    this.setDisabledIcons = this.setDisabledIcons.bind(this);

    this.state = {
      fetchState: FetchStateE.PENDING,
      fetchedScore: score || null,
      ...defaultDisabledContext,
      ...defaultDisplayContext,
      setDisplayTextField: this.setDisplayTextField,
      setDisplayUnsubscribe: this.setDisplayUnsubscribe,
      setDisplayTitleMessage: this.setDisplayTitleMessage,
      setDisabledFeedbackButton: this.setDisabledFeedbackButton,
      setDisabledIcons: this.setDisabledIcons,
      ratingQuestion: null,
      thankYouMessage: null,
      commentQuestion: null,
      placeholderText: null,
      buttonText: null,
      scoreIconStyle: null,
      commentText: null,
    };
  }

  componentDidMount(): void {
    this.getData();
  }

  setDisplayTextField(display: boolean): void {
    this.setState({
      displayTextField: display,
    });
  }

  setDisplayUnsubscribe(display: boolean): void {
    this.setState({
      displayUnsubscribe: display,
    });
  }

  setDisplayTitleMessage(display: boolean): void {
    this.setState({
      displayTitleMessage: display,
    });
  }

  setDisabledFeedbackButton(disabled: boolean): void {
    this.setState({
      disabledFeedbackButton: disabled,
    });
  }

  setDisabledIcons(disabled: boolean): void {
    this.setState({
      disabledIcons: disabled,
    });
  }

  async patchScore(score: ScoreT): Promise<void> {
    const { ratingId } = this.props;
    if (ratingId) {
      await ratingRequest(ratingId, 'PATCH', {
        score: window.parseInt(score, 10),
      }).then(() => {
        this.setDisplayTextField(true);
      });
    }
  }

  async getData(): Promise<void> {
    const { score, ratingId } = this.props;

    if (!ratingId) {
      return;
    }

    try {
      const response = await ratingRequest(ratingId, 'GET');

      const {
        data: {
          effective_customization: {
            rating_question: ratingQuestion,
            thank_you_message: thankYouMessage,
            comment_question: commentQuestion,
            placeholder_text: placeholderText,
            button_text: buttonText,
            look_and_feel: scoreIconStyle,
          },
          score: fetchedScoreNumber,
          comment: fetchedComment,
          language: language,
        },
      }: Data = await response.json();
      log.score = fetchedScoreNumber;
      log.comment = fetchedComment;

      const fetchedScore =
        fetchedScoreNumber !== undefined &&
        fetchedScoreNumber !== null &&
        isValidScore(fetchedScoreNumber.toString())
          ? (fetchedScoreNumber.toString() as ScoreT)
          : null;

      this.setState({
        thankYouMessage,
        commentQuestion,
        ratingQuestion,
        placeholderText,
        buttonText,
        scoreIconStyle,
        fetchedScore: fetchedScore || score,
        commentText: fetchedComment,
        fetchState: FetchStateE.GET_SUCCESS,
        language: language,
      });

      // The score and comment have already been submitted, so we show the thank you message.
      if (fetchedComment && fetchedScore) {
        this.setDisplayTitleMessage(false);
        this.setDisplayTextField(true);
        this.setDisplayUnsubscribe(false);
        this.setDisabledIcons(true);
        return;
      }

      // If the score in the URL parameters is valid, and the score has not been submitted yet,
      // we submit the score automatically on initial page load. This caters for the case where the
      // user clicks on the link in the email for the first time. To prevent abuse, we do not patch
      // the score again on page load if a previous score exists. Instead the user will have to
      // manually change the score and submit the feedback form, with or without leaving a comment.
      if (isValidScore(score)) {
        if (!fetchedScore) {
          await this.patchScore(score);
        }

        this.setDisplayTitleMessage(true);
        this.setDisplayTextField(true);
        this.setDisplayUnsubscribe(true);
        this.setDisabledFeedbackButton(false);
      }
    } catch (e) {
      this.onError(e as Error);
    }
  }

  onSubmit(commentText: string | null): void {
    if (commentText) {
      this.setState({
        commentText,
        disabledFeedbackButton: true,
        disabledIcons: true,
      });
    }
  }

  onError(e: Error): void {
    if (window.isLocal) {
      console.error(e);
    } else {
      Sentry.captureException(e);
    }

    this.setState({
      fetchState: FetchStateE.FAILED,
    });
  }

  render(): h.JSX.Element | null {
    const { ratingId, unsubscribe, score } = this.props;
    const {
      commentText,
      thankYouMessage,
      ratingQuestion,
      commentQuestion,
      placeholderText,
      buttonText,
      fetchedScore,
      fetchState,
      scoreIconStyle,
      setDisabled,
      setDisabledFeedbackButton,
      setDisabledIcons,
      disabledFeedbackButton,
      disabledIcons,
      setDisplay,
      setDisplayTextField,
      setDisplayUnsubscribe,
      setDisplayTitleMessage,
      displayTextField,
      displayUnsubscribe,
      displayTitleMessage,
      language,
    } = this.state;

    if (fetchState === FetchStateE.PENDING) {
      return null;
    }

    if (
      !ratingId ||
      !isValidScoreIconStyle(scoreIconStyle) ||
      fetchState === FetchStateE.FAILED
    ) {
      return <Error />;
    } else if (unsubscribe === 'true') {
      return <UnsubscribePage ratingId={ratingId} lang={language} />;
    } else if (score) {
      return (
        <TextDirContext.Provider value={defaultTextDirContext}>
          <DisabledContext.Provider
            value={{
              disabledFeedbackButton,
              disabledIcons,
              setDisabled,
              setDisabledFeedbackButton,
              setDisabledIcons,
            }}
          >
            <DisplayContext.Provider
              value={{
                displayTextField,
                displayUnsubscribe,
                displayTitleMessage,
                setDisplay,
                setDisplayTextField,
                setDisplayUnsubscribe,
                setDisplayTitleMessage,
              }}
            >
              <Feedback
                scoreIconStyle={scoreIconStyle}
                score={fetchedScore as ScoreT}
                commentText={commentText}
                thankYouMessage={thankYouMessage}
                ratingQuestion={ratingQuestion}
                commentQuestion={commentQuestion}
                // if the score is present, we show a thank you message as the title form,
                // since we can presume that the question was already shown (i.e in the email)
                titleMessage={thankYouMessage ?? ''}
                placeholderText={placeholderText}
                buttonText={buttonText}
                ratingId={ratingId}
                onSubmit={this.onSubmit}
                onError={this.onError}
                patchScore={this.patchScore}
                unsubscribeUrl={`/?ratingId=${ratingId}&unsubscribe=true`}
                language={language}
              />
            </DisplayContext.Provider>
          </DisabledContext.Provider>
        </TextDirContext.Provider>
      );
    }

    return (
      <TextDirContext.Provider value={defaultTextDirContext}>
        <DisabledContext.Provider
          value={{
            disabledFeedbackButton,
            disabledIcons,
            setDisabled,
            setDisabledFeedbackButton,
            setDisabledIcons,
          }}
        >
          <DisplayContext.Provider
            value={{
              displayTextField,
              displayUnsubscribe,
              displayTitleMessage,
              setDisplay,
              setDisplayTextField,
              setDisplayUnsubscribe,
              setDisplayTitleMessage,
            }}
          >
            <Feedback
              scoreIconStyle={scoreIconStyle}
              score={fetchedScore as ScoreT}
              commentText={commentText}
              thankYouMessage={thankYouMessage}
              ratingQuestion={ratingQuestion}
              commentQuestion={commentQuestion}
              // if the score is not present, we show a rating question as the title form,
              // since we can presume that the question was not previously shown
              // (i.e sending a link through sms does not leave room for questions)
              titleMessage={ratingQuestion ?? ''}
              placeholderText={placeholderText}
              buttonText={buttonText}
              ratingId={ratingId}
              onSubmit={this.onSubmit}
              onError={this.onError}
              patchScore={this.patchScore}
              unsubscribeUrl={`/?ratingId=${ratingId}&unsubscribe=true`}
              language={language}
            />
          </DisplayContext.Provider>
        </DisabledContext.Provider>
      </TextDirContext.Provider>
    );
  }
}
