import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

import { TextDirContext } from '../../contexts/textDir';
import ErrorIcon from './icons/error.png';

import * as style from './error.scss';

export const Error: FunctionalComponent = () => {
  const { textDirProp } = useContext(TextDirContext) || {};

  return (
    <div className={style.root}>
      <div className={style.iconContainer}>
        <img className={style.icon} src={ErrorIcon} alt="" />
      </div>
      <div className={style.error} {...textDirProp}>
        Something seems to have gone wrong
      </div>
      <div className={style.instructions} {...textDirProp}>
        Please go back to your original survey and try again
      </div>
    </div>
  );
};
