import { FunctionalComponent, h, Fragment } from 'preact';
import classnames from 'classnames';

import { RatingComponentProps } from './rating';
import { ScoreT } from '../../types/response';

import * as style from './rating.scss';

import AngerEmoji from './icons/emojis/anger.png';
import SadEmoji from './icons/emojis/sad.png';
import MmmhEmoji from './icons/emojis/mmmh.png';
import HappyEmoji from './icons/emojis/happy.png';
import AwesomeEmoji from './icons/emojis/awesome.png';

const ratings: Record<ScoreT, string> = {
  1: AngerEmoji,
  2: SadEmoji,
  3: MmmhEmoji,
  4: HappyEmoji,
  5: AwesomeEmoji,
};

export const Emoji: FunctionalComponent<Required<RatingComponentProps>> = ({
  selectedScore,
  onChange,
}: Required<RatingComponentProps>) => {
  return (
    <Fragment>
      <button
        key={selectedScore}
        onClick={(): void => onChange(selectedScore)}
        className={classnames(style.ratingButton, {
          [style.selected]: true,
        })}
        disabled={true}
      >
        <img
          src={ratings[selectedScore]}
          alt={selectedScore}
          className={style.imgIcon}
        />
      </button>
    </Fragment>
  );
};
